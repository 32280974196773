import React from 'react';
import ReactDOM from 'react-dom';
import {compose} from 'redux';

import AppStateHOC from '../lib/app-state-hoc.jsx';
import GUI from '../containers/gui.jsx';
import HashParserHOC from '../lib/hash-parser-hoc.jsx';
import log from '../lib/log.js';

import {code_cache} from '../lib/phzh/code-cache.js';
import {url_params} from '../lib/phzh/url-params.js';

const onClickLogo = () => {
	window.location = 'https://scratch.mit.edu';
};

const handleTelemetryModalCancel = () => {
	log('User canceled telemetry modal');
};

const handleTelemetryModalOptIn = () => {
	log('User opted into telemetry');
};

const handleTelemetryModalOptOut = () => {
	log('User opted out of telemetry');
};

/*
 * Render the GUI playground. This is a separate function because importing anything
 * that instantiates the VM causes unsupported browsers to crash
 * {object} appTarget - the DOM element to render to
 */
export default appTarget => {
	GUI.setAppElement(appTarget);

	// note that redux's 'compose' function is just being used as a general utility to make
	// the hierarchy of HOC constructor calls clearer here; it has nothing to do with redux's
	// ability to compose reducers.
	const WrappedGui = compose(
		AppStateHOC,
		HashParserHOC
	)(GUI);

	// TODO a hack for testing the backpack, allow backpack host to be set by url param
	const backpackHostMatches = window.location.href.match(/[?&]backpack_host=([^&]*)&?/);
	const backpackHost = backpackHostMatches ? backpackHostMatches[1] : null;

	const scratchDesktopMatches = window.location.href.match(/[?&]isScratchDesktop=([^&]+)/);
	let simulateScratchDesktop;
	if (scratchDesktopMatches) {
		try {
			// parse 'true' into `true`, 'false' into `false`, etc.
			simulateScratchDesktop = JSON.parse(scratchDesktopMatches[1]);
		} catch {
			// it's not JSON so just use the string
			// note that a typo like "falsy" will be treated as true
			simulateScratchDesktop = scratchDesktopMatches[1];
		}
	}

	if (process.env.NODE_ENV === 'production' && typeof window === 'object') {
		// Warn before navigating away
		window.onbeforeunload = () => true;
	}

	// begin(phzh:project_load) try to load a project passed via query parameter
	const vm_init_handle = vm => {
		let project_loaded = false;
        let project_uuid   = null;

		vm.runtime.on('PROJECT_LOADED', () => {
			if (!project_loaded) {
				const project_file = url_params.get('project');
				console.log(`Decoded url project: ${project_file}`);

				if (project_file) {
					fetch(project_file)
						.then(response => {
							if (response.ok) {
								return response.arrayBuffer();
							} else {
								console.error('Failed to fetch project: ' + response.statusText);
							}
						})
						.then(arrayBuffer => {
							if (arrayBuffer) {
								project_loaded  = true;
								vm.loadProject(arrayBuffer)
									.catch(error => {
										project_loaded = false;
										console.error('Failed to load project. ' + error);
									});
							}
						});
				}
			}
		});
	};
	// end(phzh:project_load)

	ReactDOM.render(
		// important: this is checking whether `simulateScratchDesktop` is truthy, not just defined!
		simulateScratchDesktop ?
		<WrappedGui
		canEditTitle
		isScratchDesktop
		showTelemetryModal
		canSave={false}
		onTelemetryModalCancel={handleTelemetryModalCancel}
		onTelemetryModalOptIn={handleTelemetryModalOptIn}
		onTelemetryModalOptOut={handleTelemetryModalOptOut}
		/> :
		<WrappedGui
		canEditTitle
		backpackVisible
		showComingSoon
		backpackHost={backpackHost}
		canSave={false}
		onClickLogo={onClickLogo}
		onVmInit={vm_init_handle} // phzh:project_load
		/>,
		appTarget);
};
